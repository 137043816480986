<template>
    <div>
    <div class="table-page-search-wrapper">
      <div style="margin-bottom:12px">
          <a-radio-group v-model="subject" @change="changeSubject">
            <a-radio-button :value="item.value" v-for="(item,index) in subjectArrCopy" :key="index">{{item.label}}</a-radio-button>
          
          </a-radio-group>
        </div>
      <a-form layout="inline" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
        <a-row :gutter="24">
          <a-col :span="6"  v-if="userInfo.leader">
               <a-form-item label="组织(部门):">
                    <a-tree-select
                      v-model="searchDeptId"
                      style="width: 100%"
                      :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                      :tree-data="treeData"
                      placeholder="请选择"
                      tree-default-expand-all
                    >
                    </a-tree-select>
                  </a-form-item>
          </a-col>
           <a-col :span="6"  v-if="userInfo.leader">
              <a-form-item label="员工姓名">
                <a-select
                  show-search
                  @search="searchClassAdmin"
                  v-model="classAdminId"
                  :filter-option="filterOption"
                  option-filter-prop="children"
                  placeholder="请选择"
                  allow-clear
                >
                  <a-select-option :value="i.uuid" v-for="i of classAdminList" :key="i.uuid">
                    {{ i.fullName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
             <a-col :span="8">
                  <a-form-item label="时间范围">
                    <a-range-picker @change="onChangeDate" v-model="searchDateArr" format="YYYY-MM-DD" />
                  </a-form-item>
                </a-col>
          <a-col :md="4" :sm="24" style="float: right; text-align: right">
            <span class="table-page-search-submitButtons" :style="{ float: 'right', overflow: 'hidden' } || {}">
              <a-button type="primary" style="margin-right: 10px"  @click="initSearchData('search')">查询</a-button>
              <a-button style="margin-right: 10px" @click="resetCondition">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-wrap">
      <div style="margin-bottom:12px;">{{initOptionsType(subject,subjectArr)}}分享率为<span style="color:#43d186;">{{shareRate}}</span>（{{searchStartTime}} 至 {{searchEndTime}}），以下列表为该时段分享学员明细<span v-if="userInfo.roleName === 'CLASS_ADMIN'">，点击该时段<span style="color:#43d186;cursor:pointer" @click="hrefNoShare">未分享学员</span></span></div>
          <a-table
            :loading="showLoading"
            :columns="columns"
            :data-source="tableData"
            @change="handleChange"
            :pagination="initPagination"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
          >
            <template slot="operation" slot-scope="operation, record">
                <span class="operation" @click="lookDetail(record)">查看明细</span>
              </template>
             <template slot="studentName" slot-scope="studentName, record">
                <span>{{`${studentName}（${record.studentCode}）`}}</span>
              </template>
              <!-- <template slot="subject" slot-scope="subject, record">
                <span>{{initOptionsType(subject,subjectArr)}}</span>
              </template> -->
                <span slot="customTitle" class="title-style">成功分享次数 
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>分享审批通过即认为成功分享</span>
                      </template>
                      <i class="explainTC"></i>
                    </a-tooltip>
               </span>
                 <span slot="customTitle1" class="title-style">提交分享次数 
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>该学员科目分享审批数量</span>
                      </template>
                      <i class="explainTC"></i>
                    </a-tooltip>
               </span>
          </a-table>
    </div>
  </div>
</template>

<script>
import storage from 'store';
import {
  getTreeList,
  queryShareDetail,
  selClassAdmin
} from '@/api/headTeacher';
export default {
  name: 'studentShareDetail',
  data() {
    return {
    shareRate:null,
    classAdminList:[],
    classAdminId:undefined,
     subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
     subjectArrCopy:[],
    subject:'',
     tableData:[],
      showLoading:false,
      searchStartTime: '',
      searchEndTime: '',
      searchDateArr: [],
      searchName: undefined,
      searchDeptId: undefined,
      treeData: [],
      userInfo:{},
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '学员',
          dataIndex: 'studentName',
          key: 'studentName',
          scopedSlots: { customRender: 'studentName' },
       

        },
        {
          title: '科目',
          dataIndex: 'subject',
          key: 'subject',
        //  scopedSlots: { customRender: 'subject' },
        },
         {
              dataIndex: 'successNumber',
              key: 'successNumber',
              slots: { title: 'customTitle' },
              // scopedSlots: { customRender: 'name' },
            },
        {
              dataIndex: 'submitNumber',
              key: 'submitNumber',
              slots: { title: 'customTitle1' },
              // scopedSlots: { customRender: 'name' },
            },
       {
          title: '操作',
          width: 100,
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
     initPagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
      },
      pageNum: 1,
      current: 0,
      pageSize: 10,
    }
  },
  methods:{
  hrefNoShare(){
  let notShardStudent={
    subject:this.subject,
    deptId:this.searchDeptId,
    classAdminId:this.$route.query.accountId || this.classAdminId,
    startTime:this.searchStartTime,
    endTime:this.searchEndTime
  }
 this.$router.push({ path: '/myStudent',query:{notShardStudent:JSON.stringify(notShardStudent)} });

  },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    searchClassAdmin(name) {
      const params = {
        name,
      };
      selClassAdmin(params).then((res) => {
        this.classAdminList = res.data.content;
      });
    },
 initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },
  changeSubject(val){
    this.initSearchData('search')
  },
    lookDetail(data) {
      const routeUrl = this.$router.resolve({
        path: '/sharingLessons',
        query: {
          studentId: data.studentId,
        },
      });
      window.open(routeUrl.href, '_blank');
    },
    onChangeDate(date) {
      if (date.length > 0) {
        this.searchStartTime = date[0].format('YYYY-MM-DD');
        this.searchEndTime = date[1].format('YYYY-MM-DD');
      } else {
        this.searchStartTime = null;
        this.searchEndTime = null;
      }
    
    },
    getTreeList() {
        getTreeList().then((res) => {
          this.treeData = res.data;
          // 成员数据回显组织部门
          this.searchDeptId =this.$route.query.deptId || this.userInfo.leaderDeptId;
            this.initSearchData('search')
        });
      },
   initSearchData(act) {
 if (!this.searchDeptId) {
        this.$message.warning('请先选择组织(部门)');
        return false;
      }
      if (this.searchDateArr.length === 0) {
        this.$message.warning('请选择时间范围');
        return false;
      }
      this.showLoading = true;
      if (act === 'search') {
        this.pageNum = 1;
      }

      const params = {
        classAdminId:this.$route.query.accountId || this.classAdminId,
        deptId: this.searchDeptId,
        startTime: this.searchStartTime,
        endTime: this.searchEndTime,
        currentPage: this.pageNum,
        pageSize: this.pageSize,
        subject:this.subject
      };
      queryShareDetail(params)
        .then((res) => {
          const { data } = res;
          this.tableData = data.content;
          this.shareRate=data.shareRate;
          this.current = data.currentPage;
          this.pageSize = data.pageSize;
          this.initPagination.total = data.totalCount;
          this.initPagination.current = data.currentPage;
          this.showLoading = false;
        })
        .catch((error) => {
          this.showLoading = false;
        });
    },
   resetCondition() {
      this.searchDateArr = [];
      this.searchStartTime = '';
      this.searchEndTime = '';
      this.searchName = undefined;
      this.searchDeptId = undefined;
      this.initSearchData('search')
    },
   handleChange(data, filters, sorter) {
      this.pageNum = data.current;
      this.initPagination.current = data.current;
      this.initPagination.pageSize = data.pageSize;
      this.pageSize = data.pageSize;
      this.initSearchData();
    },
  },
   created() {
    this.userInfo = storage.get('userInfo');
    this.subject=this.$route.query.subject || this.userInfo.subject || 'CHINESE'
    this.subjectArrCopy=this.subjectArr.filter((item)=>{
      return (item.value=== this.subject) ||  this.userInfo.leader
    })
 
    // 获取组织部门,只有管理员才可以看组织数据页面
  this.searchStartTime=this.$route.query.startTime;
  this.searchEndTime=this.$route.query.endTime;
  this.searchDateArr=[ this.searchStartTime, this.searchEndTime]
    this.getTreeList();
  
  },
}
</script>

<style lang="less" scoped>
.table-wrap {
  padding: 20px;
  background: #fff;
}
/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item > .ant-form-item-label {
  width: 105px;
  text-align: right;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
  .operation {
    color: #43d186;
    cursor: pointer;
  }
  .explainTC {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('~@/assets/explain.png') no-repeat center;
  background-size: 100%;
  margin-left:8px;
  cursor: pointer;
}
.title-style{
  display: flex;
  align-items: center;
}
</style>